export const addIndicator = async (indicators) => {
    try {
        await fetch('https://us-central1-desarrollo-ci.cloudfunctions.net/functions_status_findep_mx_dev', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                APPLY_CRUD: "post",
                rows: indicators,
            }),
        });
    } catch (error) {
        console.log(error);
        return {
            error: 'Ocurrio un error',
        };
    }
};

export const getAllIndicators = async () => {
    try {
        const res = await fetch('https://us-central1-desarrollo-ci.cloudfunctions.net/functions_status_findep_mx_dev', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                APPLY_CRUD: "get",
            }),
        });
        const indicators = await res.text();
        console.log(indicators);
        return [
            {
                "id": "fe95bbe2-193d-4c93-bd89-ae431354c597",
                "home": "Cloud",
                "icono_home": "Cloud",
                "categoria": "Cloud",
                "icono_categoria": "Cloud",
                "subcategoria": "Cloud",
                "icono_subcategoria": "Cloud",
                "titulo_pagina": "Cloud",
                "detalle_pagina": "Cloud",
                "empresa": "A",
                "nombre": "Cloud",
                "descripcion": "Cloud",
                "leyenda": "Cloud",
                "sufijo": "",
                "vs": "Cloud",
                "calculado": "#",
                "calculado_comparativo": "#",
                "query_valor_principal": "Cloud",
                "query_valor_secundario": "Cloud",
                "query_valor_comparativo": "Cloud",
                "tipo_limite": "semaforo",
                "red": "1",
                "green": "2",
                "yellow": "1"
            },
            {
                "id": "07702ba2-4984-4f40-9dee-831871c0765d",
                "home": "Cloud",
                "icono_home": "Cloud",
                "categoria": "Cloud",
                "icono_categoria": "Cloud",
                "subcategoria": "Cloud",
                "icono_subcategoria": "Cloud",
                "titulo_pagina": "Cloud",
                "detalle_pagina": "Cloud",
                "empresa": "A",
                "nombre": "Cloud 2",
                "descripcion": "Cloud 2",
                "leyenda": "Cloud 2",
                "sufijo": "",
                "vs": "Cloud 2",
                "calculado": "#",
                "calculado_comparativo": "#",
                "query_valor_principal": "Cloud 2",
                "query_valor_secundario": "Cloud 2",
                "query_valor_comparativo": "Cloud 2",
                "tipo_limite": "dentro de",
                "red": "1",
                "green": "2"
            },
            {
                "id": "43633b1b-d105-4923-ad36-6e935efdaa35",
                "home": "Cloud",
                "icono_home": "Cloud",
                "categoria": "Cloud",
                "icono_categoria": "Cloud",
                "subcategoria": "Cloud",
                "icono_subcategoria": "Cloud",
                "titulo_pagina": "Cloud",
                "detalle_pagina": "Cloud",
                "empresa": "B",
                "nombre": "Cloud 3",
                "descripcion": "Cloud 3",
                "leyenda": "Cloud 3",
                "sufijo": "",
                "vs": "Cloud 3",
                "calculado": "$",
                "calculado_comparativo": "#",
                "query_valor_principal": "Cloud 3",
                "query_valor_secundario": "Cloud 3",
                "query_valor_comparativo": "Cloud 3",
                "tipo_limite": "dentro de",
                "red": "3",
                "green": "3"
            }
        ];
    } catch (error) {
        console.log(error);
        return {
            error: 'Ocurrio un error',
        };
    }
};