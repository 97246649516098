import { Route, Routes } from "react-router-dom";
import CreateViews from "../createViews/CreateViews";
// import EditViews from "../editViews/EditViews";

const Admin = () => {
    return (
        <Routes>
            <Route index element={<CreateViews />} />
            {/* <Route path="/editar" element={<EditViews />} /> */}
        </Routes>
    );
};

export default Admin;