import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

const OutsideClickDetector = ({ children, customClass = '', onOutsideClick = () => {}, style = {} }) => {

    const outsideRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (outsideRef && !outsideRef.current.contains(e.target)) {
                onOutsideClick();
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [onOutsideClick]);

    return (
        <div ref={outsideRef} className={customClass} style={style}>
            {children}
        </div>
    );
};

OutsideClickDetector.propTypes = {
    customClass: PropTypes.string,
    onOutsideClick: PropTypes.func,
    style: PropTypes.object,
};

export default OutsideClickDetector;
