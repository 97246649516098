import { Link } from 'react-router-dom';
import Icon from '../../icon/Icon';
import './SummaryCard.scss';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import AnimateHeight from 'react-animate-height';

const SummaryCard = ({ title, icon = 'Warning', sections, open = false }) => {

    const [bodyHeight, setBodyHeight] = useState(0);

    const [active, setActive] = useState(false);
    useEffect(() => {
        setActive(open);
        setBodyHeight(open ? 'auto' : 0);
    }, [open]);

    const handleClick = () => {
        setActive(!active);
        setBodyHeight(bodyHeight === 0 ? 'auto' : 0);
    };

    return (
        <div className={`summary-card${active ? ' active' : ''}`}>
            <div className='header' onClick={handleClick}>
                <span className='icon-wrapper' style={{ alignItems: !!sections.length ? 'center' : 'baseline' }}>
                    {
                        !!sections?.length ?
                            <span>{sections.length}</span>
                            : <Icon className='icon' name={icon} />
                    }
                </span>
                <span className='title'>{title}</span>
            </div>
            <AnimateHeight
                id='body--animated'
                duration={300}
                height={bodyHeight}
            >
                <div className='body'>
                    {
                        !!sections?.length && sections.map((section, i) => (
                            <div key={`${section.title}-${i}`} className='section'>
                                <span className='section-title'>{section.sectionTitle}</span>
                                <div className='btns'>
                                    {
                                        section?.btns?.length && section.btns.map((btn, btnIndex) => (
                                            <div key={`${btn.to}-${btnIndex}`} className='btn-wrapper'>
                                                <Link to={btn.to}>{btn.text}</Link>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        ))
                    }
                </div>
            </AnimateHeight>
        </div>
    );
};

SummaryCard.propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.string,
    sections: PropTypes.arrayOf(PropTypes.object).isRequired,
    open: PropTypes.bool,
};

export default SummaryCard;