import './StatusFindep.scss';
import { useContext, useEffect, useState } from 'react';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { getMenu, getRouterStructure } from './services/menu.service';
import Menu from './components/menu/Menu';
import {
    Link, Route, Routes,
    useNavigate,
} from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from './firebase';
import Summary from './views/summary/Summary';
import { Helmet } from 'react-helmet';
import IndicatorsTemplatePage from './views/indicatorsTemplatePage/IndicatorsTemplatePage';
import logo from './images/logo.png';
import Admin from './views/admin/Admin';
import { AuthContext } from './contexts/AuthContext';
import { verifyToken } from './services/auth.service';
import { logoutAction } from './actions/AuthActions';
import OutsideClickDetector from './components/outsideClickDetector/OutsideClickDetector';

const StatusFindep = () => {

    const navigate = useNavigate();
    const { state: { user }, dispatch } = useContext(AuthContext);

    const [triggerVerifyToken, setTriggerVerifyToken] = useState(false);
    useEffect(() => {
        (async () => {
            if (!!user?.token) {
                const isTokenValid = await verifyToken(user.token);
                if (!isTokenValid) {
                    dispatch(logoutAction());
                    navigate('/login', { replace: true });
                }
            } else {
                dispatch(logoutAction());
                navigate('/login', { replace: true });
            }
        })();
    }, [user, triggerVerifyToken, navigate, dispatch]);

    // DELETE
    useEffect(() => {
        signInWithEmailAndPassword(auth, 'bi.informa.findep@gmail.com', 'xF@gCCAyBU*V')
            .then((userCredential) => { })
            .catch((error) => console.log(error));
    }, []);

    const [routerStructure, setRouterStructure] = useState([]);
    useEffect(() => {
        (async () => {
            const router = await getRouterStructure();
            if (!router?.error) {
                setRouterStructure(router);
            } else {
                console.log(router.error);
            }
        })();
    }, []);

    const [menu, setMenu] = useState([]);
    useEffect(() => {
        (
            async () => {
                const menu = await getMenu();
                if (!menu?.error) {
                    setMenu(menu);
                } else {
                    console.log(menu.error);
                }
            }
        )();
    }, []);

    const getRouteElement = (view) => {
        switch (view) {
            // case 'indicators':
            //     return <IndicatorsTemplatePage />

            // ### Añadir pagina 404 cuando existan mas tipos de paginas template
            default:
                return <IndicatorsTemplatePage />
        }
    };

    const [closed, setClosed] = useState(false);
    const handleClick = () => {
        setClosed(!closed);
    };

    const [menuOpen, setMenuOpen] = useState(false);
    const handleClickMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const handleOutsideSidebarClick = () => {
        setMenuOpen(false);
    };

    const handleSubmenuClick = (index, text, to) => {
        // TODO: Propuesta para menu colapsable, se expandira el menu cuando se de clic a submenus de nivel index === 1 con el menu colapsado
        // if (closed && index === 1) {
        //     setClosed(false);
        // }
        if (menuOpen && !!to) {
            setMenuOpen(false);
        }
    }

    const [showLogoutModal, setShowLogoutModal] = useState(false);
    const logout = () => {
        dispatch(logoutAction());
        setTriggerVerifyToken(!triggerVerifyToken);
    };

    return (
        <div className={`status-findep${closed ? ' closed' : ' open'}`}>
            <Helmet>
                <html lang="es" />
            </Helmet>
            <div className={`navbar${menuOpen ? ' active' : ''}`}>
                <span
                    className='logout-btn'
                    onClick={(e) => {
                        e.stopPropagation();
                        setShowLogoutModal(!showLogoutModal);
                    }}
                >
                    {
                        !!user?.picture &&
                        <img src={user.picture} alt='' />
                    }
                    {user?.email}
                    <OutsideClickDetector customClass={`logout-modal${showLogoutModal ? ' show' : ''}`} onOutsideClick={() => setShowLogoutModal(false)}>
                        <button onClick={logout}>Cerrar sesión</button>
                    </OutsideClickDetector>
                </span>
            </div>
            <div className={`sidebar-background${menuOpen ? ' active' : ''}`} onClick={handleOutsideSidebarClick} />
            <div className={`sidebar${menuOpen ? ' active' : ''}`}>
                <div className='menu-toggle' onClick={handleClickMenu} />
                <div className='sidebar-logo'>
                    <Link to='/'>
                        <img className='logo-img' src={logo} alt='logo' />
                    </Link>
                </div>
                <div className='sidebar-menu-container'>
                    <div className='sidebar-menu'>
                        <Menu data={menu} closed={closed} onSubmenuClick={handleSubmenuClick} />
                    </div>
                    <div
                        className='close-btn'
                        onClick={handleClick}
                    >
                        {
                            closed ?
                                <KeyboardArrowRight />
                                : <KeyboardArrowLeft />
                        }
                    </div>
                </div>
            </div>
            <div className='content'>
                {/* <Outlet /> */}
                <Routes>
                    <Route index element={<Summary />} />
                    <Route path='/admin/*' element={<Admin />} />
                    {
                        !!routerStructure.length && routerStructure.map((route) => (
                            <Route key={route.path} path={route.path} element={getRouteElement(route.view)} />
                        ))
                    }
                </Routes>
            </div>
        </div>
    )
};

export default StatusFindep;