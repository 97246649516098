import { getDoc, doc, getDocs, collection } from 'firebase/firestore';
import { db } from '../firebase';

export const getIndicatorsData = async (area, category, subcategory) => {
    try {
        const querySnapshot = await getDocs(collection(db, 'StatusFindep', area, category, subcategory, 'secciones'));
        if (!querySnapshot.empty) {
            return querySnapshot.docs.map((doc) => doc.data());
        } else {
            return {
                error: `No se encontraron datos para ${area}/${category}/${subcategory}`,
            };
        }
    } catch (error) {
        console.log(error);
        return {
            error: 'Ocurrió un problema, intentalo más tarde',
        };
    }
};

export const getViewData = async (area, category, subcategory) => {
    try {
        const docSnap = await getDoc(doc(db, 'StatusFindep', area, category, subcategory));
        if (docSnap.exists()) {
            return docSnap.data();
        } else {
            return {
                error: `No se encontraron datos de la vista ${area}/${category}/${subcategory}`,
            };
        }
    } catch (error) {
        console.log(error);
        return {
            error: 'Ocurrió un problema, intentalo más tarde',
        };
    }
};