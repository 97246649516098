import { useEffect, useState } from 'react';
import './LastUpdate.scss';
import { getLastUpdate } from '../../services/generalInfo.service';

const LastUpdate = () => {

    const [lastUpdate, setLastUpdate] = useState('');
    useEffect(() => {
        (async () => {
            const lastUpdate = await getLastUpdate();
            if (!lastUpdate?.error) {
                setLastUpdate(lastUpdate);
            } else {
                console.log(lastUpdate.error);
            }
        })();
    }, []);

    return (
        !!lastUpdate &&
        <p className='last-update'>Última actualización: {lastUpdate}</p>
    );
};

export default LastUpdate;