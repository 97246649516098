import { AUTH_TYPES } from "../types/AuthTypes";

export const authReducer = (state, action) => {
    switch (action.type) {
        case AUTH_TYPES.login:
            localStorage.setItem('user', JSON.stringify(action.payload));
            return {
                ...state,
                user: action.payload,
            };
        case AUTH_TYPES.logout:
            localStorage.removeItem('user');
            return {
                ...state,
                user: null,
            };

        default:
            return state;
    }
};