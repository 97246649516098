import './Menu.scss';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import Icon from '../icon/Icon';
import { NavLink } from 'react-router-dom';

const Menu = ({ data, index = 0, showHideClass = 'menu--show', closed = false, onSubmenuClick = () => { } }) => {

    const [menuOpen, setMenuOpen] = useState({});
    useEffect(() => {
        if (!!data?.length) {
            let state = {};
            data.forEach(item => {
                state = { ...state, [`${index}-${item.text}`]: index === 0 };
            });
            setMenuOpen(state);
        }
    }, [data, index]);

    // REVISAR: Propuesta para menu colapsable, se cerraran los submenus de nivel index >= 2 cuando se colapse el menu
    // useEffect(() => {
    //     if (closed && index >= 2) {

    //     }
    // }, [closed]);

    const handleClick = (text) => {
        setMenuOpen({ ...menuOpen, [`${index}-${text}`]: !menuOpen[`${index}-${text}`] });
        onSubmenuClick(index, text);
    };

    const handleNavLinkClick = (text, to) => {
        onSubmenuClick(index, text, to);
    };

    return (
        <ul className={`menu${closed ? ' menu--closed' : ''} ${showHideClass}`}>
            {
                !!data?.length &&
                data.map((item) => (
                    !!item?.children?.length ?
                        <li key={`${index}-${item.text}`} className='menu-item--submenu'>
                            <div className='menu-item' onClick={() => handleClick(item.text)}>
                                <Icon className='menu-item-icon' name={item.icon} style={{ marginLeft: index * 20 }} />
                                <span className='menu-item-text'>{item.text}</span>
                                <Icon className={`menu-item-icon--submenu${menuOpen[`${index}-${item.text}`] ? ' menu-item-icon--submenu--rotate180' : ''}`} name="ArrowDropDown" />
                            </div>
                            <Menu data={item.children} index={index + 1} showHideClass={!menuOpen[`${index}-${item.text}`] ? 'menu--hide' : 'menu--show'} closed={closed} onSubmenuClick={onSubmenuClick} />
                        </li>
                        : <li key={`${index}-${item.text}`}>
                            <NavLink className={({ isActive }) => `menu-item${isActive ? ' menu-item--active' : ''}`} to={item.to} onClick={() => handleNavLinkClick(item.text, item.to)}>
                                <Icon className='menu-item-icon' name={item.icon} style={{ marginLeft: index * 20 }} />
                                <span className='menu-item-text'>{item.text}</span>
                            </NavLink>
                        </li>
                ))
            }
        </ul>
    );
};

Menu.propTypes = {
    data: PropTypes.array.isRequired,
    index: PropTypes.number,
    showHideClass: PropTypes.string,
    closed: PropTypes.bool,
    onSubmenuClick: PropTypes.func,
};

export default Menu;