export const verifyToken = async (token) => {
    try {
        if (token) {
            const res = await fetch(`https://us-central1-desarrollo-ci.cloudfunctions.net/status-findep-auth?token=${token}`);
            if (!!res.ok) {
                const data = await res.json();
                return !!data?.isAuth ? true : false;
            }
        }
        return false;
    } catch (error) {
        return false;
    }
};