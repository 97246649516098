import { useState } from "react";

export const useForm = (initialState = {}) => {
    const [values, setValues] = useState(initialState);
    const reset = () => {
        setValues(initialState);
    };
    const handleInputChange = ({ target }) => {
        if (target.type === 'checkbox') {
            let checkboxValue = [...values?.[target.name]];
            if (target.checked) {
                checkboxValue = [...checkboxValue, target.value];
            } else {
                checkboxValue = checkboxValue.filter(cb => cb !== target.value);
            }
            setValues({ ...values, [target.name]: checkboxValue });
        } else if (target.type === 'textList') {
            let textList = [...values?.[target.name]];
            if (target.action === 'remove') {
                textList = textList.filter((textItem) => textItem !== target.value);
            } else {
                textList = [...textList, target.value];
            }
            setValues({ ...values, [target.name]: textList })
        } else {
            setValues({ ...values, [target.name]: target.value });
        }
    };
    return [values, handleInputChange, reset];
};