import './IndicatorPillCard.scss';
import PropTypes from 'prop-types';
import { Info, TrendingUp } from '@mui/icons-material';
import { useEffect, useState } from 'react';

const IndicatorPillCard = ({ title, description, indicator, goal, trend, time, icon, alert, open = false, label = '', cumplimiento = '' }) => {

    const getBgColor = (alert) => {
        switch (alert) {
            case 'yellow':
                return '#FFD100';

            default:
                return alert;
        }
    };

    const getBgLighterColor = (alert) => {
        switch (alert) {
            case 'yellow':
                return '#fff5cc';
            case 'green':
                return '#cce5cc';
            case 'red':
                return '#ffcccc';

            default:
                return alert;
        }
    };

    const [activeClass, setActiveClass] = useState(open);
    const handleClick = () => {
        setActiveClass(!activeClass);
    };

    useEffect(() => {
        setActiveClass(open);
    }, [open]);

    const [showLabel, setShowLabel] = useState(false);

    return (
        <div className='indicator-pill-card-wrapper'>
            {
                !!label &&
                <>
                    <Info className='label-icon' onClick={() => setShowLabel(!showLabel)} />
                    <span className={`label${showLabel ? ' active' : ''}`}>{label}</span>
                </>
            }
            <div className={`indicator-pill-card${activeClass ? ' active' : ''}`} onClick={handleClick}>
                <div className='header' style={{ backgroundColor: getBgColor(alert) }}>
                    <div>
                        <span className='title'>{title}</span>
                        <span className='description'>{description}</span>
                    </div>
                    <span className='trend-vs-time'>
                        <TrendingUp className={`icon${alert === 'red' ? ' icon--down' : ''}`} />
                        <span>{trend}</span> vs <span className='time'>{time}</span>
                    </span>
                </div>
                <div className='body' style={{ backgroundColor: getBgLighterColor(alert) }}>
                    <span className='indicator'>{indicator}</span>
                    <span className='goal'>{goal}</span>
                    <span className='cumplimiento'>{cumplimiento}</span>
                </div>
            </div>
        </div>
    );
};

IndicatorPillCard.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    indicator: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    goal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    trend: PropTypes.string,
    time: PropTypes.string,
};

export default IndicatorPillCard;