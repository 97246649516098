import { useEffect, useState } from 'react';
import './Tabs.scss';
import PropTypes from 'prop-types';
import { Cancel } from '@mui/icons-material';

const Tabs = ({ tabIds, renderContent, onCloseTab = () => {} }) => {

    const [activeTab, setActiveTab] = useState(0);

    useEffect(() => {
        if (activeTab > (tabIds.length - 1)) {
            setActiveTab(tabIds.length - 1);
        }
    }, [tabIds, activeTab]);

    const handleClick = (index) => {
        setActiveTab(index);
    };

    const handleCloseClick = () => {
        onCloseTab(tabIds[activeTab]);
    };

    return (
        <div className='tabs'>
            <ol>
                {
                    tabIds.map((tabId, i) => (
                        <li
                            key={`${tabId}-${i}`}
                            className={activeTab === i ? 'active-tab' : ''}
                            onClick={() => handleClick(i)}
                        >
                            {tabId}
                            <Cancel className='tab-close-icon' onClick={handleCloseClick} />
                        </li>
                    ))
                }
            </ol>
            <div className='tabs-content'>
                {renderContent(tabIds[activeTab])}
            </div>
        </div>
    );
};

Tabs.propTypes = {
    tabIds: PropTypes.array.isRequired,
    renderContent: PropTypes.func.isRequired,
    onCloseTab: PropTypes.func,
};

export default Tabs;