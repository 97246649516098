import './IndicatorsTemplatePage.scss';
import { useResolvedPath } from "react-router-dom";
import { useCallback, useEffect, useState } from 'react';
import { Cancel, UnfoldLess, UnfoldMore } from '@mui/icons-material';
import { getIndicatorsData, getViewData } from '../../services/indicators.service';
import IndicatorPillCard from '../../components/cards/indicatorPillCard/IndicatorPillCard';
import LastUpdate from '../../components/lastUpdate/LastUpdate';

const IndicatorsTemplatePage = () => {

    const { pathname } = useResolvedPath();
    const splitPathname = useCallback(
        (path) => {
            let route = path.split('/');
            route.shift();
            return route;
        },
        []
    );

    const [viewData, setViewData] = useState({});
    useEffect(() => {
        (async () => {
            if (!!pathname) {
                const viewData = await getViewData(...splitPathname(pathname));
                setViewData(viewData);
            }
        })()
    }, [pathname, splitPathname]);

    const [indicatorsData, setIndicatorsData] = useState([]);
    useEffect(() => {
        (async () => {
            if (!!pathname) {
                const indicators = await getIndicatorsData(...splitPathname(pathname));
                setIndicatorsData(indicators);
            }
        })();

    }, [pathname, splitPathname]);

    const [openAll, setOpenAll] = useState(false);
    const handleClick = () => {
        setOpenAll(!openAll);
    };

    return (
        <div className="indicators-template-page">
            <h2>
                {viewData?.pageTitle}
                <button className='open-btn' onClick={handleClick}>
                    {
                        openAll ?
                            <UnfoldLess className='icon' />
                            : <UnfoldMore className='icon' />
                    }
                </button>
            </h2>
            {
                !!viewData?.pageDescription &&
                <p>{viewData.pageDescription}</p>
            }
            <LastUpdate />
            {
                !!indicatorsData?.length ?
                    indicatorsData.map((indicatorSection, i) => (
                        <div key={`${indicatorSection.section}-${i}`} className='cards-section'>
                            <h3>{indicatorSection.section}</h3>
                            {
                                !!indicatorSection?.cards?.length &&
                                <div className='cards-container'>
                                    {
                                        indicatorSection.cards.map((card, i) => (
                                            <IndicatorPillCard
                                                key={`${card.title}-${i}`}
                                                title={card.title}
                                                description={card.description}
                                                indicator={card.indicator}
                                                goal={card.goal}
                                                trend={card.trend}
                                                time={card.time}
                                                icon={card.icon}
                                                alert={card.alert}
                                                label={card.label}
                                                cumplimiento={card.cumplimiento}
                                                open={openAll}
                                            />
                                        ))
                                    }
                                </div>
                            }
                        </div>
                    ))
                    : !!indicatorsData?.error &&
                    <div className='error-wrapper'>
                        <div className='error'>
                            <Cancel className='icon' />
                            <span>{indicatorsData.error}</span>
                        </div>
                    </div>
            }
        </div>
    );
};

export default IndicatorsTemplatePage;