import PropTypes from 'prop-types';
import * as MaterialIcons from '@mui/icons-material';

const Icon = ({ name, ...restProps }) => {

    const CustomIcon = MaterialIcons?.[name] || MaterialIcons.DisabledByDefault;

    return (
        <CustomIcon {...restProps} />
    );
};

Icon.propTypes = {
    name: PropTypes.string.isRequired,
};

export default Icon;