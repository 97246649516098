import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";

export const getLastUpdate = async () => {
    try {
        const docSnap = await getDoc(doc(db, 'StatusFindep', 'infoGeneral'));
        if (docSnap.exists() && !!docSnap.data()?.lastUpdate) {
            return docSnap.data().lastUpdate;
        } else {
            return {
                error: 'No se encontró ultima fecha de actualización',
            };
        }
    } catch (error) {
        console.log(error);
        return {
            error: 'Ocurrió un problema, intentalo más tarde',
        };
    }
};