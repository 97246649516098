import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";

export const getSummary = async () => {
    try {
        const docSnap = await getDoc(doc(db, 'StatusFindep', 'resumen'));
        if (docSnap.exists()) {
            return docSnap.data().resumen;
        } else {
            return {
                error: 'No se encontraron información para Resumen Alertas',
            }
        }
    } catch (error) {
        return {
            error: 'Ocurrió un problema, intentalo más tarde',
        };
    }
};