import { useRef, useState } from 'react';
import './CreateViews.scss';
import { useForm } from '../../hooks/useForm';
// import IndicatorPillCard from '../../components/cards/indicatorPillCard/IndicatorPillCard';
// import { getMenu } from '../../services/menu.service';
// import Menu from '../../components/menu/Menu';
import { isIconValid } from '../../utils';
import { addIndicator } from '../../services/views.service';
import Tabs from '../../components/tabs/Tabs';
import { v4 as uuidv4 } from 'uuid';
import { Delete } from '@mui/icons-material';

const CreateViews = () => {

    const pageTitleRef = useRef(null);

    // const [validate, setValidate] = useState(false);

    // const [menu, setMenu] = useState([]);
    // useEffect(() => {
    //     (
    //         async () => {
    //             const menu = await getMenu();
    //             if (!menu?.error) {
    //                 setMenu(menu);
    //             } else {
    //                 console.log(menu.error);
    //             }
    //         }
    //     )();
    // }, []);

    const initialState = {
        root: '',
        rootIcon: '',
        category: '',
        categoryIcon: '',
        subcategory: '',
        subcategoryIcon: '',
        pageTitle: '',
        pageDetail: '',
        sectionTitles: [],
    };
    const [formValues, handleInputChange, reset] = useForm(initialState);
    const {
        root,
        rootIcon,
        category,
        categoryIcon,
        subcategory,
        subcategoryIcon,
        pageTitle,
        pageDetail,
        sectionTitles,
    } = formValues;

    const initialCards = {
        0: {
            cardTitle: '',
            cardDescription: '',
            suffix: '',
            vs: '',
            indicatorType: '#',
            cardLeyend: '',
            numeratorQuery: '',
            denominatorQuery: '',
            comparativeIndicatorType: '#',
            comparativeQuery: '',
            limitType: 'semaforo',
            red: '',
            yellow: '',
            green: '',
        }
    };
    const [cards, setCards] = useState(initialCards);
    const handleCardsInputChange = (e, key, sectionId = '') => {
        if (!sectionId) {
            setCards({
                ...cards,
                [key]: {
                    ...cards[key],
                    [e.target.name]: e.target.value,
                }
            });
        } else {
            setCards({
                ...cards,
                [sectionId]: {
                    ...cards?.[sectionId],
                    [key]: {
                        ...cards?.[sectionId]?.[key],
                        [e.target.name]: e.target.value,
                    },
                },
            });
        }
    };

    const initialCardErrors = {
        0: {
            cardTitle: '',
            cardDescription: '',
            suffix: '',
            vs: '',
            indicatorType: '',
            cardLeyend: '',
            numeratorQuery: '',
            denominatorQuery: '',
            comparativeIndicatorType: '',
            comparativeQuery: '',
            limitType: '',
            red: '',
            yellow: '',
            green: '',
        },
    };

    const initialErrors = {
        root: '',
        rootIcon: '',
        category: '',
        categoryIcon: '',
        subcategory: '',
        subcategoryIcon: '',
        pageTitle: '',
        pageDetail: '',
        sectionTitles: '',
        cards: initialCardErrors,
    };
    const [errors, setErrors] = useState(initialErrors);

    const [hasCardsErrors, setHasCardsErrors] = useState(false);

    const isFormValid = () => {
        let _errors = {
            root: !root ? 'Este campo no puede estar vacio' : '',
            category: !category ? 'Este campo no puede estar vacio' : '',
            subcategory: !subcategory ? 'Este campo no puede estar vacio' : '',
            rootIcon: (!rootIcon && 'Este campo no puede estar vacio') || (!isIconValid(rootIcon) && 'El nombre del icono es incorrecto') || '',
            categoryIcon: (!categoryIcon && 'Este campo no puede estar vacio') || (!isIconValid(categoryIcon) && 'El nombre del icono es incorrecto') || '',
            subcategoryIcon: (!subcategoryIcon && 'Este campo no puede estar vacio') || (!isIconValid(subcategoryIcon) && 'El nombre del icono es incorrecto') || '',
            pageTitle: !pageTitle ? 'Este campo no puede estar vacio' : '',
            pageDetail: '',
            sectionTitles: (!sectionTitles?.length && 'Este campo debe contener al menos una sección') || '',
        };
        const errorsCards = Object.keys(cards).reduce((prevVal, currentVal) => {
            return {
                ...prevVal,
                [currentVal]: Object.keys(cards[currentVal]).reduce((prev, current) => ({
                    ...prev,
                    [current]: {
                        cardTitle: !cards[currentVal][current].cardTitle ? 'Este campo no puede estar vacio' : '',
                        cardDescription: !cards[currentVal][current].cardDescription ? 'Este campo no puede estar vacio' : '',
                        suffix: '',
                        vs: !cards[currentVal][current].vs ? 'Este campo no puede estar vacio' : '',
                        indicatorType: !cards[currentVal][current].indicatorType ? 'Este campo no puede estar vacio' : '',
                        cardLeyend: '',
                        numeratorQuery: !cards[currentVal][current].numeratorQuery ? 'Este campo no puede estar vacio' : '',
                        denominatorQuery: !cards[currentVal][current].denominatorQuery ? 'Este campo no puede estar vacio' : '',
                        // comparativeIndicatorType: !cards[currentVal][current].comparativeIndicatorType ? 'Este campo no puede estar vacio' : '',
                        // comparativeQuery: !cards[currentVal][current].comparativeQuery ? 'Este campo no puede estar vacio' : '',
                        comparativeIndicatorType: '',
                        comparativeQuery: '',
                        limitType: !cards[currentVal][current].limitType ? 'Este campo no puede estar vacio' : '',
                        red: !cards[currentVal][current].red ? 'Este campo no puede estar vacio' : '',
                        yellow: cards[currentVal][current].limitType === 'semaforo' && !cards[currentVal][current].yellow ? 'Este campo no puede estar vacio' : '',
                        green: !cards[currentVal][current].green ? 'Este campo no puede estar vacio' : '',
                    },
                }), {}),
            };
        }, {});
        const cardsHasErrors = Object.keys(errorsCards).some((sectionId) => Object.keys(errorsCards[sectionId]).some((key) => Object.values(errorsCards[sectionId][key]).some(field => !!field)));
        setHasCardsErrors(cardsHasErrors);
        if (!!Object.values(_errors).some(e => !!e) || cardsHasErrors) {
            setErrors({
                ...initialErrors,
                ..._errors,
                cards: errorsCards,
            });
            return false
        };
        setErrors({
            ...initialErrors,
        });
        return true;
    };

    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');

    const [loading, setLoading] = useState(false);
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (isFormValid()) {
            if (!!cards) {
                let indicators = [];
                Object.keys(cards).forEach((section) => {
                    Object.values(cards[section]).forEach((card) => {
                        let indicator = {
                            id: uuidv4(),
                            home: root,
                            icono_home: rootIcon,
                            categoria: category,
                            icono_categoria: categoryIcon,
                            subcategoria: subcategory,
                            icono_subcategoria: subcategoryIcon,
                            titulo_pagina: pageTitle,
                            detalle_pagina: pageDetail,
                            empresa: section,
                            nombre: card.cardTitle,
                            descripcion: card.cardDescription,
                            leyenda: card.cardLeyend,
                            sufijo: card.suffix,
                            vs: card.vs,
                            calculado: card.indicatorType,
                            calculado_comparativo: card.comparativeIndicatorType,
                            query_valor_principal: card.numeratorQuery,
                            query_valor_secundario: card.denominatorQuery,
                            query_valor_comparativo: card.comparativeQuery,
                            tipo_limite: card.limitType,
                            red: card.red,
                            green: card.green,
                        };
                        if (card.limitType === 'semaforo') {
                            indicator = {
                                ...indicator,
                                yellow: card.yellow,
                            };
                        }
                        indicators.push(indicator);
                    });
                });
                setLoading(true);
                const res = await addIndicator(indicators);
                setLoading(false);
                if (!!res?.error) {
                    setModalMessage('Ocurrio un problema, lo sentimos.');
                } else {
                    setModalMessage('El indicador se guardo con éxito.');
                }
                setShowModal(true);
            }
        }
    };

    const [sectionTitle, setSectionTitle] = useState('');
    const [sectionTitleEvent, setSectionTitleEvent] = useState(null);
    const handleSectionTitleChange = (e) => {
        if (!!errors[e.target.name]) {
            setErrors(initialErrors);
        }
        setSectionTitle(e.target.value);
        setSectionTitleEvent({
            target: {
                type: 'textList',
                name: e.target.name,
                value: e.target.value,
            }
        });
    };

    const [indicatorCollaps, setIndicatorCollaps] = useState({ 0: true });

    const handleClick = (e) => {
        e.preventDefault();
        if (!sectionTitleEvent?.target?.value) {
            setErrors({
                ...initialErrors,
                sectionTitles: 'Este campo no puede estar vacio',
            });
        } else if (sectionTitles.includes(sectionTitleEvent.target.value)) {
            setErrors({
                ...initialErrors,
                sectionTitles: 'El nombre de una sección no se puede repetir',
            });
        } else {
            setSectionTitle('');
            handleInputChange(sectionTitleEvent);
            if (!sectionTitles.length) {
                setCards({ [sectionTitleEvent.target.value]: initialCards });
                setIndicatorCollaps({
                    [sectionTitleEvent.target.value]: {
                        0: true,
                    },
                });
                setErrors({
                    ...errors,
                    cards: {
                        [sectionTitleEvent.target.value]: initialCardErrors,
                    }
                });
            } else {
                setCards({ ...cards, [sectionTitleEvent.target.value]: initialCards });
                setIndicatorCollaps({
                    ...indicatorCollaps,
                    [sectionTitleEvent.target.value]: {
                        0: true,
                    },
                });
                setErrors({
                    ...errors,
                    cards: {
                        ...errors?.cards,
                        [sectionTitleEvent.target.value]: initialCardErrors,
                    },
                });
            }
        }
    };

    const clearCards = () => {
        let _cards = Object.keys(cards).reduce((prevVal, currentVal) => {
            return {
                ...prevVal,
                [currentVal]: Object.keys(cards[currentVal]).reduce((prev, current) => ({ ...prev, [current]: initialCards[0] }), {}),
            };
        }, {});
        setCards(_cards);
    };

    const [counter, setCounter] = useState(1);
    const handleAddCardClick = (e, sectionId) => {
        e.preventDefault();
        // if (!sectionTitles.length) {}
        setCards({ ...cards, [sectionId]: { ...cards[sectionId], [counter]: initialCards[0] } });
        setIndicatorCollaps({
            ...indicatorCollaps,
            [sectionId]: {
                ...indicatorCollaps?.[sectionId],
                [counter]: false,
            }
        });
        setErrors({
            ...errors,
            cards: {
                ...errors?.cards,
                [sectionId]: {
                    ...errors?.cards?.[sectionId],
                    [counter]: initialCardErrors[0],
                }
            },
        });
        setCounter(counter + 1);
    };

    const handleIndicatorClick = (sectionId, key) => {
        setIndicatorCollaps({
            ...indicatorCollaps,
            [sectionId]: {
                ...indicatorCollaps[sectionId],
                [key]: !indicatorCollaps[sectionId][key],
            }
        });
    };

    return (
        <div className='create-views'>
            <h2>Formulario Crear vistas</h2>
            <form className='form-wrapper' onSubmit={handleSubmit} noValidate>
                <div className='create-views-form menu'>
                    <div className='form-row'>
                        <div className='input-wrapper'>
                            <input
                                type='text'
                                name='root'
                                required
                                value={root}
                                onChange={handleInputChange}
                                autoComplete='off'
                            />
                            <span>Raíz <em>*</em></span>
                            <i />
                            <div className={`error${errors?.root ? ' show' : ''}`}>{errors.root}</div>
                        </div>
                        <div className='input-wrapper'>
                            <input
                                type='text'
                                name='rootIcon'
                                required
                                value={rootIcon}
                                onChange={handleInputChange}
                                autoComplete='off'
                            />
                            <span>Icono de raíz <em>*</em></span>
                            <i />
                            <div className={`error${errors?.rootIcon ? ' show' : ''}`}>{errors.rootIcon}</div>
                        </div>
                    </div>
                    <div className='form-row'>
                        <div className='input-wrapper'>
                            <input
                                type='text'
                                name='category'
                                required
                                value={category}
                                onChange={handleInputChange}
                                autoComplete='off'
                            />
                            <span>Categoría <em>*</em></span>
                            <i />
                            <div className={`error${errors?.category ? ' show' : ''}`}>{errors.category}</div>
                        </div>
                        <div className='input-wrapper'>
                            <input
                                type='text'
                                name='categoryIcon'
                                required
                                value={categoryIcon}
                                onChange={handleInputChange}
                                autoComplete='off'
                            />
                            <span>Icono de categoria <em>*</em></span>
                            <i />
                            <div className={`error${errors?.categoryIcon ? ' show' : ''}`}>{errors.categoryIcon}</div>
                        </div>
                    </div>
                    <div className='form-row'>
                        <div className='input-wrapper'>
                            <input
                                type='text'
                                name='subcategory'
                                required
                                value={subcategory}
                                onChange={handleInputChange}
                                autoComplete='off'
                            />
                            <span>Subcategoría <em>*</em></span>
                            <i />
                            <div className={`error${errors?.subcategory ? ' show' : ''}`}>{errors.subcategory}</div>
                        </div>
                        <div className='input-wrapper'>
                            <input
                                type='text'
                                name='subcategoryIcon'
                                required
                                value={subcategoryIcon}
                                onChange={handleInputChange}
                                autoComplete='off'
                            />
                            <span>Icono de subcategoria <em>*</em></span>
                            <i />
                            <div className={`error${errors?.subcategoryIcon ? ' show' : ''}`}>{errors.subcategoryIcon}</div>
                        </div>
                    </div>
                </div>
                {/* <div className='previous-view menu'>
                    <div className='menu-wrapper'>
                        <Menu data={menu} />
                    </div>
                </div> */}
                <div className='create-views-form indicators'>
                    <div className='form-row'>
                        <div className='input-wrapper'>
                            <input
                                type='text'
                                name='pageTitle'
                                required
                                value={pageTitle}
                                onChange={handleInputChange}
                                autoComplete='off'
                                ref={pageTitleRef}
                            />
                            <span>Titulo de la pagina <em>*</em></span>
                            <i />
                            <div className={`error${errors?.pageTitle ? ' show' : ''}`}>{errors.pageTitle}</div>
                        </div>
                        <div className='input-wrapper'>
                            <input
                                type='text'
                                name='pageDetail'
                                required
                                value={pageDetail}
                                onChange={handleInputChange}
                                autoComplete='off'
                            />
                            <span>Detalle de la pagina</span>
                            <i />
                            <div className={`error${errors?.pageDetail ? ' show' : ''}`}>{errors.pageDetail}</div>
                        </div>
                    </div>
                    <div className='form-row'>
                        <div className='wrapper-add-btn'>
                            <div className='input-wrapper'>
                                <input
                                    type='text'
                                    name='sectionTitles'
                                    required
                                    value={sectionTitle}
                                    onChange={handleSectionTitleChange}
                                    autoComplete='off'
                                />
                                <span>Titulo de la sección <em>*</em></span>
                                <i />
                                <div className={`error${errors?.sectionTitles ? ' show' : ''}`}>{errors.sectionTitles}</div>
                            </div>
                            <button onClick={handleClick}>
                                Agregar
                            </button>
                        </div>
                    </div>
                    {
                        !!sectionTitles?.length &&
                        <Tabs
                            tabIds={sectionTitles}
                            onCloseTab={(tabId) => {
                                if (!!cards?.[tabId]) {
                                    let _cards = { ...cards };
                                    delete _cards[tabId];
                                    handleInputChange({
                                        target: {
                                            type: 'textList',
                                            name: 'sectionTitles',
                                            value: tabId,
                                            action: 'remove'
                                        }
                                    });
                                    setCards(_cards);
                                }
                                if (!!indicatorCollaps?.[tabId]) {
                                    let _indicatorsCollaps = { ...indicatorCollaps };
                                    delete _indicatorsCollaps[tabId];
                                    setIndicatorCollaps(_indicatorsCollaps);
                                }
                                if (!!errors?.cards?.[tabId]) {
                                    let errorsCards = { ...errors.cards };
                                    delete errorsCards[tabId];
                                    setErrors({
                                        ...errors,
                                        cards: errorsCards,
                                    });
                                }
                            }}
                            renderContent={(tabId) => (
                                <div className='card-form-wrapper'>
                                    <button
                                        className='add-card-btn'
                                        onClick={(e) => handleAddCardClick(e, tabId)}
                                    >
                                        +
                                    </button>
                                    {
                                        !!cards?.[tabId] && !!Object.keys(cards[tabId]).length && Object.keys(cards[tabId]).map((key) => (
                                            <div key={`card-${key}`} className={`card-form${!indicatorCollaps[tabId][key] ? ' hide' : (hasCardsErrors ? ' card-form--errors' : '')}`}>
                                                <span onClick={() => handleIndicatorClick(tabId, key)}>
                                                    {cards[tabId][key].cardTitle || `Indicador ${Number(key) + 1}`}
                                                    <button
                                                        className='remove-card-btn'
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            if (!!cards?.[tabId]?.[key]) {
                                                                let _cards = { ...cards };
                                                                delete cards[tabId][key];
                                                                setCards(_cards);
                                                            }
                                                            if (!!indicatorCollaps?.[tabId]) {
                                                                let _indicatorsCollaps = { ...indicatorCollaps };
                                                                delete _indicatorsCollaps[tabId][key];
                                                                setIndicatorCollaps(_indicatorsCollaps);
                                                            }
                                                            if (!!errors?.cards?.[tabId]?.[key]) {
                                                                let errorsCards = { ...errors.cards };
                                                                delete errorsCards[tabId][key];
                                                                setErrors({
                                                                    ...errors,
                                                                    cards: errorsCards,
                                                                });
                                                            }
                                                        }}
                                                    >
                                                        <Delete className='remove-icon' />
                                                    </button>
                                                </span>
                                                <div className='form-row'>
                                                    <div className='input-wrapper'>
                                                        <input
                                                            type='text'
                                                            name='cardTitle'
                                                            required
                                                            value={cards[tabId][key].cardTitle}
                                                            onChange={(e) => handleCardsInputChange(e, key, tabId)}
                                                            autoComplete='off'
                                                        />
                                                        <span>Titulo de la card <em>*</em></span>
                                                        <i />
                                                        <div className={`error${errors?.cards?.[tabId]?.[key]?.cardTitle ? ' show' : ''}`}>{errors?.cards?.[tabId]?.[key]?.cardTitle}</div>
                                                    </div>
                                                    <div className='input-wrapper'>
                                                        <input
                                                            type='text'
                                                            name='cardDescription'
                                                            required
                                                            value={cards[tabId][key].cardDescription}
                                                            onChange={(e) => handleCardsInputChange(e, key, tabId)}
                                                            autoComplete='off'
                                                        />
                                                        <span>Descripción de la card <em>*</em></span>
                                                        <i />
                                                        <div className={`error${errors?.cards?.[tabId]?.[key]?.cardDescription ? ' show' : ''}`}>{errors?.cards?.[tabId]?.[key]?.cardDescription}</div>
                                                    </div>
                                                </div>
                                                <div className='form-row'>
                                                    <div className='input-wrapper'>
                                                        <input
                                                            type='text'
                                                            name='cardLeyend'
                                                            required
                                                            value={cards[tabId][key].cardLeyend}
                                                            onChange={(e) => handleCardsInputChange(e, key, tabId)}
                                                            autoComplete='off'
                                                        />
                                                        <span>Leyenda de la card</span>
                                                        <i />
                                                        <div className={`error${errors?.cards?.[tabId]?.[key]?.cardLeyend ? ' show' : ''}`}>{errors?.cards?.[tabId]?.[key]?.cardLeyend}</div>
                                                    </div>
                                                    <div className='input-wrapper'>
                                                        <input
                                                            type='text'
                                                            name='suffix'
                                                            required
                                                            value={cards[tabId][key].suffix}
                                                            onChange={(e) => handleCardsInputChange(e, key, tabId)}
                                                            autoComplete='off'
                                                        />
                                                        <span>Sufijo</span>
                                                        <i />
                                                        <div className={`error${errors?.cards?.[tabId]?.[key]?.suffix ? ' show' : ''}`}>{errors?.cards?.[tabId]?.[key]?.suffix}</div>
                                                    </div>
                                                </div>
                                                <div className='form-row'>
                                                    <div className='input-wrapper'>
                                                        <input
                                                            type='text'
                                                            name='vs'
                                                            required
                                                            value={cards[tabId][key].vs}
                                                            onChange={(e) => handleCardsInputChange(e, key, tabId)}
                                                            autoComplete='off'
                                                        />
                                                        <span>VS <em>*</em></span>
                                                        <i />
                                                        <div className={`error${errors?.cards?.[tabId]?.[key]?.vs ? ' show' : ''}`}>{errors?.cards?.[tabId]?.[key]?.vs}</div>
                                                    </div>
                                                </div>
                                                <div className='group-form-rows'>
                                                    <div className='form-row'>
                                                        <div className='input-wrapper--select'>
                                                            <span>Tipo de indicador <em>*</em></span>
                                                            <select name='indicatorType' value={cards[tabId][key].indicatorType} onChange={(e) => handleCardsInputChange(e, key, tabId)}>
                                                                <option value='#'>#</option>
                                                                <option value='$'>$</option>
                                                                <option value='%'>%</option>
                                                            </select>
                                                            <div className={`error${errors?.cards?.[tabId]?.[key]?.indicatorType ? ' show' : ''}`}>{errors?.cards?.[tabId]?.[key]?.indicatorType}</div>
                                                        </div>
                                                    </div>
                                                    <div className='form-row'>
                                                        <div className='input-wrapper--textarea'>
                                                            <span>Query valor principal <em>*</em></span>
                                                            <textarea
                                                                name='numeratorQuery'
                                                                value={cards[tabId][key].numeratorQuery}
                                                                onChange={(e) => handleCardsInputChange(e, key, tabId)}
                                                            />
                                                            <div className={`error${errors?.cards?.[tabId]?.[key]?.numeratorQuery ? ' show' : ''}`}>{errors?.cards?.[tabId]?.[key]?.numeratorQuery}</div>
                                                        </div>
                                                    </div>
                                                    <div className='form-row'>
                                                        <div className='input-wrapper--textarea'>
                                                            <span>Query valor secundario <em>*</em></span>
                                                            <textarea
                                                                name='denominatorQuery'
                                                                value={cards[tabId][key].denominatorQuery}
                                                                onChange={(e) => handleCardsInputChange(e, key, tabId)}
                                                            />
                                                            <div className={`error${errors?.cards?.[tabId]?.[key]?.denominatorQuery ? ' show' : ''}`}>{errors?.cards?.[tabId]?.[key]?.denominatorQuery}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='group-form-rows'>
                                                    <div className='input-wrapper--select'>
                                                        <span>Tipo de indicador comparativo</span>
                                                        <select name='comparativeIndicatorType' value={cards[tabId][key].comparativeIndicatorType} onChange={(e) => handleCardsInputChange(e, key, tabId)}>
                                                            <option value='#'>#</option>
                                                            <option value='$'>$</option>
                                                            <option value='%'>%</option>
                                                        </select>
                                                        <div className={`error${errors?.cards?.[tabId]?.[key]?.comparativeIndicatorType ? ' show' : ''}`}>{errors?.cards?.[tabId]?.[key]?.comparativeIndicatorType}</div>
                                                    </div>
                                                    <div className='form-row'>
                                                        <div className='input-wrapper--textarea'>
                                                            <span>Query valor comparativo</span>
                                                            <textarea
                                                                name='comparativeQuery'
                                                                value={cards[tabId][key].comparativeQuery}
                                                                onChange={(e) => handleCardsInputChange(e, key, tabId)}
                                                            />
                                                            <div className={`error${errors?.cards?.[tabId]?.[key]?.comparativeQuery ? ' show' : ''}`}>{errors?.cards?.[tabId]?.[key]?.comparativeQuery}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='form-row'>
                                                    <div className='input-wrapper--select'>
                                                        <span>Tipo de limite <em>*</em></span>
                                                        <select name='limitType' value={cards[tabId][key].limitType} onChange={(e) => handleCardsInputChange(e, key, tabId)}>
                                                            <option value='semaforo'>Semáforo</option>
                                                            <option value='dentro de'>Dentro de</option>
                                                            <option value='fuera de'>Fuera de</option>
                                                        </select>
                                                        <div className={`error${errors?.cards?.[tabId]?.[key]?.limitType ? ' show' : ''}`}>{errors?.cards?.[tabId]?.[key]?.limitType}</div>
                                                    </div>
                                                    <div className='input-wrapper'>
                                                        <input
                                                            type='number'
                                                            name='red'
                                                            required
                                                            value={cards[tabId][key].red}
                                                            onChange={(e) => handleCardsInputChange(e, key, tabId)}
                                                            autoComplete='off'
                                                        />
                                                        <span>Rojo <em>*</em></span>
                                                        <i />
                                                        <div className={`error${errors?.cards?.[tabId]?.[key]?.red ? ' show' : ''}`}>{errors?.cards?.[tabId]?.[key]?.red}</div>
                                                    </div>
                                                </div>
                                                <div className='form-row'>
                                                    {
                                                        !!cards[tabId][key]?.limitType && cards[tabId][key].limitType === 'semaforo' &&
                                                        <div className='input-wrapper'>
                                                            <input
                                                                type='number'
                                                                name='yellow'
                                                                required
                                                                value={cards[tabId][key].yellow}
                                                                onChange={(e) => handleCardsInputChange(e, key, tabId)}
                                                                autoComplete='off'
                                                            />
                                                            <span>Amarillo <em>*</em></span>
                                                            <i />
                                                            <div className={`error${errors?.cards?.[tabId]?.[key]?.yellow ? ' show' : ''}`}>{errors?.cards?.[tabId]?.[key]?.yellow}</div>
                                                        </div>
                                                    }
                                                    <div className='input-wrapper'>
                                                        <input
                                                            type='number'
                                                            name='green'
                                                            required
                                                            value={cards[tabId][key].green}
                                                            onChange={(e) => handleCardsInputChange(e, key, tabId)}
                                                            autoComplete='off'
                                                        />
                                                        <span>Verde <em>*</em></span>
                                                        <i />
                                                        <div className={`error${errors?.cards?.[tabId]?.[key]?.green ? ' show' : ''}`}>{errors?.cards?.[tabId]?.[key]?.green}</div>
                                                    </div>

                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            )}
                        />
                    }
                    <div className='form-row center'>
                        {/* <input className={`validate-btn${validate ? ' validated-btn' : ''}`} type='submit' value={validate ? 'Enviar' : 'Validar'} disabled={loading} /> */}
                        <input className='validate-btn validated-btn' type='submit' value='Guardar' disabled={loading} />
                    </div>
                </div>
                {/* <div className='previous-view'>
                    <h2 onClick={() => pageTitleRef.current.focus()}>{pageTitle || 'Titulo de la pagina'}</h2>
                    <h3>{sectionTitles || 'Titulo de la sección'}</h3>
                    <div className='card-wrapper'>
                        <IndicatorPillCard
                            title={cardTitle || 'Titulo de la card'}
                            description={cardDescription || 'Descripción de la card'}
                            indicator={numeratorQuery || 'Query numerador'}
                            goal={denominatorQuery || 'Query denominador'}
                            trend={suffix || 'Prefijo'}
                            time={vs || 'VS'}
                            alert={'yellow'}
                            open={true}
                        />
                    </div>
                </div> */}
            </form>
            {
                showModal &&
                <div className='modal'>
                    <div className='modal-card'>
                        <span>{modalMessage}</span>
                        <div className='btns'>
                            <button onClick={() => {
                                reset();
                                setShowModal(false);
                            }}>Limpiar formulario</button>
                            <button onClick={() => {
                                clearCards();
                                setShowModal(false);
                            }}>Aceptar</button>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};

export default CreateViews;