import './Login.scss';
import { GoogleLogin } from '@react-oauth/google';
import logo from '../../images/logo.png';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { loginAction } from '../../actions/AuthActions';
import { useNavigate } from 'react-router-dom';

const Login = () => {

    const navigate = useNavigate();
    const { state: { user }, dispatch } = useContext(AuthContext);
    const [error, setError] = useState('');

    useEffect(() => {
        if (!!user) navigate('/', { replace: true });
    }, [user, navigate]);

    const handleSuccess = async (response) => {
        try {
            if (!response?.credential) {
                console.log('Google login error');
                setError('Ocurrió un problema, intentalo más tarde');
            } else {
                const res = await fetch("https://oauth2.googleapis.com/tokeninfo?id_token=" + response.credential);
                const info = await res.json();
                if (!info?.email) {
                    console.log('Google api email error');
                    setError('No se encontro un email valido');
                } else {
                    const authRes = await fetch('https://us-central1-desarrollo-ci.cloudfunctions.net/status-findep-auth', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            email: info.email,
                        }),
                    });
                    if (authRes.ok) {
                        const data = await authRes.json();
                        if (!!data?.isAuth) {
                            dispatch(loginAction({
                                email: info.email,
                                name: info.name,
                                picture: info.picture,
                                token: data?.token || null,
                            }));
                        }
                    } else {
                        const message = await authRes.text();
                        console.log(message);
                        setError('No tienes permisos para acceder');
                    }
                }
            }
        } catch (error) {
            console.error(error);
            setError('Ocurrió un problema, intentalo más tarde');
        }
    };

    const handleError = (error) => {
        console.log(error);
        setError('Ocurrió un problema, intentalo más tarde');
    };

    return (
        <div className='login'>
            <div className='login-modal'>
                <div className='square'>
                    <img className='logo-img' src={logo} alt='logo' />
                </div>
                <div className='square'>
                    <GoogleLogin
                        theme='filled_blue'
                        onSuccess={handleSuccess}
                        onError={handleError}
                    />
                    {
                        !!error &&
                        <span className='error'>{error}</span>
                    }
                </div>
            </div>
        </div>
    );
};

export default Login;

// const appId = '6250886f3e0fb078a9a8ba36cd';
// const APP_TRACE = 'UUVyNUCGJc7ch9FfwSzPBjF9MhnXGxnB';
// let url = 'https://bff-sso-apps-service.tysonprod.com/v1/api/auth/app/data/'
// const r = await fetch(url, {
//     method: 'POST',
//     headers: {
//         'X-App-Trace-ID': APP_TRACE,
//         'Content-Type': 'application/json',
//     },
//     body: {
//         app: appId,
//         userEmail: info.email,
//     },
// });
// const data = await r.json();