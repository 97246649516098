import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCgarJ3h-q2MCCx6_g8kcDE5JakV03lE7E",
  authDomain: "desarrollo-ci.firebaseapp.com",
  databaseURL: "https://desarrollo-ci.firebaseio.com",
  projectId: "desarrollo-ci",
  storageBucket: "desarrollo-ci.appspot.com",
  messagingSenderId: "846156431852",
  appId: "1:846156431852:web:0a990bf9b4767f26c000c9",
//   measurementId: "G-87ENLKCCT4",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);