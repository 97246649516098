import { useReducer } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { authReducer } from '../../reducers/AuthReducer';

const AUTH_INITIAL_STATE = {};

const AuthProvider = ({ children }) => {

    const initAuthProvider = () => {
        try {
            const user = JSON.parse(localStorage.getItem('user'));
            return {
                user,
            };
        } catch (error) {
            return {};
        }
    };

    const [state, dispatch] = useReducer(authReducer, AUTH_INITIAL_STATE, initAuthProvider);

    return (
        <AuthContext.Provider value={{ state, dispatch }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;