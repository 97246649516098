import { useEffect, useState } from 'react';
import { getSummary } from '../../services/summary.service';
import './Summary.scss';
import SummaryCard from '../../components/cards/summaryCard/SummaryCard';
import { UnfoldLess, UnfoldMore } from '@mui/icons-material';
import LastUpdate from '../../components/lastUpdate/LastUpdate';

const Summary = () => {

    const [summary, setSummary] = useState([]);
    useEffect(() => {
        (async () => {
            const summaryCards = await getSummary();
            if (!summaryCards?.error) {
                setSummary(summaryCards);
            } else {
                console.log(summaryCards.error);
            }
        })();
    }, []);

    const [openAll, setOpenAll] = useState(false);
    const handleClick = () => {
        setOpenAll(!openAll);
    };

    return (
        <div className='summary'>
            <div className='title-wrapper'>
                <h2>
                    Resumen Alertas
                </h2>
                <button className='open-btn' onClick={handleClick}>
                    {
                        openAll ?
                            <UnfoldLess className='icon' />
                            : <UnfoldMore className='icon' />
                    }
                </button>
            </div>
            <LastUpdate />
            <div className='summary-cards'>
                {
                    !!summary?.length && summary.map((item, i) => (
                        <SummaryCard
                            key={`${item.title}-${i}`}
                            title={item.title}
                            icon={item.icon}
                            sections={item.sections}
                            open={openAll}
                        />
                    ))
                }
            </div>
        </div>
    );
};

export default Summary;